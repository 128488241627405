import { render, staticRenderFns } from "./UserCollect.vue?vue&type=template&id=7677f00e&scoped=true&"
import script from "./UserCollect.vue?vue&type=script&lang=js&"
export * from "./UserCollect.vue?vue&type=script&lang=js&"
import style0 from "./UserCollect.vue?vue&type=style&index=0&id=7677f00e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7677f00e",
  null
  
)

export default component.exports