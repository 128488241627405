<template>
	<div class="user-collect">
		<w-navTab titleText="我的收藏"></w-navTab>
		<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="nav"><w-title title="我的收藏"></w-title></div>

			<div class="collect-list" v-for="item in collectList">
				<div class="collect-item-title" @click="onClick(item)">{{ item.title }}</div>
			</div>
		</VantList>
	</div>
</template>

<script>
import User from '@/api/user';
import VantList from '@/components/VantList';
import WTitle from '@/components/Title';
import keepMiXin from '@/mixins/keep';
import scrollMiXin from '@/mixins/scroll';
import VantVendor from '@/vendor/vant';

const keep = keepMiXin(['NewsDetail']);
const scroll = scrollMiXin(['.list-view']);

export default {
	mixins: [scroll, keep],
	inject: ['themeColor'],
	name: 'UserCollect',
	data() {
		return {
			loading: true,
			finished: false,
			isEmpty: false,
			collectList: [],
			paramsPage: 1,
			paramsLimit: 20
		};
	},
	created() {
		this.onLoad();
	},
	methods: {
		onLoad() {
			this.loading = true;

			let params = {
				page: this.paramsPage,
				limit: this.paramsLimit
			};

			User.getMyCollect(params)
				.then(result => {
					this.paramsPage++;
					this.collectList = this.collectList.concat(result.data.list);
				
					this.loading = false;

					if (this.collectList.length <= 0) {
						this.isEmpty = true;
					}

					if (this.collectList.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {
					VantVendor.Toast.fail(error.msg);
				});
		},
		onClick(item) {
			let urlMap = {
				1: 'NewsDetail'
			};

			this.$router.push({
				name: 'NewsDetail',
				params: { id: item.page_id }
			});
		}
	},
	components: {
		VantList,
		WTitle
	}
};
</script>

<style lang="less" scoped>
.user-collect {
	.nav {
		padding-top: 10px;
		padding-left: 10px;
	}
	.list-view {
		padding: 0 10px;
	}
	.collect-list {
		padding: 15px 10px;
		background: #fff;
		border-radius: 8px;
		margin-top: 10px;

		.collect-item-title {
			font-size: 16px;
			line-height: 24px;
			color: #333;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
	}
}
</style>
